import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SiteItem from "../components/site-item"

const SecondPage = ({ data }) => (
  <Layout>
    <SEO
      title="English Newspapers"
      description={`Read highly trusted and most reliable Bangladeshi English newspapers online. Also, most of Bangla newspapers have an English version of there online newspapers. All of them are listed here.`}
    />
    <h1 className="text-center">All English Newspapers in Bangladesh</h1>
    <div className="newspapers-grid">
      {data.items.nodes.map(newspaper => (
        <SiteItem
          key={newspaper.frontmatter.id}
          id={newspaper.frontmatter.id}
          url={newspaper.frontmatter.url}
          title={newspaper.frontmatter.title}
          slug={newspaper.fields.slug}
          embed={newspaper.frontmatter.embed}
        />
      ))}
    </div>
    {/* <pre>{ JSON.stringify( data.items.nodes, null, 2 ) }</pre> */}
  </Layout>
)

export default SecondPage

export const query = graphql`
  query {
    items: allMarkdownRemark(
      sort: {fields: frontmatter___rank}
      filter: {frontmatter: {
        types: {in: ["en"]}
        isDead: {ne: true}
      }}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          id
          title
          url
          embed
          types
        }
      }
    }
  }
`
